.app {
  background: #474646;
  color: white;
}

.header {
  padding: 30px;
}

.footer {
  text-align: center;
  padding: 50px 0
}

.title {
  padding: 20px 0 10px 30px;
  font-size: 1.2em;
}

.stat-row {
  padding: 0 0 2px 50px
}

.stat-value {
  text-align: right;
  padding: 0 50px 2px 0
}

Button {
  width: 90px
}